
@import "../style/Global.scss";

.no-access-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .no-access-title {
        color: $ronneby-blue-1;
        font-size: 30px;
        font-weight: bold;
        text-align: center;
        margin-top: 100px;
    }

    .no-access-description {
        color: $ronneby-blue-1;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        margin-top: 20px;
    }
    

}