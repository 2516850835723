
@import "../../style/Global.scss";

.admin-container {
    margin: 40px 0px;

    .admin-options-container {
        margin-right: 0px;

        .tool-option {
            background-color: $ronneby-blue-6;
            padding: 40px;
            margin-bottom: 20px;
            border-radius: 10px;
            text-align: center;
            color: $ronneby-blue-1;
            font-weight: bold;

            &.selected {
                background-color: $ronneby-blue-1;
                color: $ronneby-white;
            }

            &:hover {
                cursor: pointer;
                background-color: $ronneby-blue-5;
            }
        }
    }

    .admin-view-container {
        padding: 40px;
        background: $ronneby-blue-6;
        border-radius: 10px;
    }
}

@media (min-width: 960px) {
    .admin-container {
        .admin-options-container {
            margin-right: 20px;
        }
    }
}