@import "../../../style/Global.scss";

$marker-size: 30px;
$marker-circle-Size: 32px;
$dot-size: 11px;

.dot-marker {
    position: absolute;
    padding: 20px;
    width: $dot-size;
    height: $dot-size;
    top: -11px;
    margin: -20px 0 0 -20px;
    outline: none;

    &:focus-visible {
        outline: auto;
        outline-color: $ronneby-black;
    }

    .dot-container {
        width: $dot-size;
        height: $dot-size;
        transform: rotate(45deg);
        left: ($dot-size / -2);
        position: relative;
        top: ($dot-size / -2);
        display: flex;
        align-items: center;
        justify-content: center;

        .circle {
            color: $ronneby-marker-grey;
            width: $dot-size;
            height: $dot-size;
            position: absolute;
            cursor: pointer;

            // -webkit-animation-name: change_color_dot;
            // -moz-animation-name: change_color_dot;
            // -ms-animation-name: change_color_dot;
            // animation-name: change_color_dot;

            &.bathing-places {
                color: $ronneby-blue-primary;
            }

            &.traffic-incident {
                color: $ronneby-red;
            }

            &.dark-blue {
                color: $ronneby-marker-dark-blue;
            }

            &.light-blue {
                color: $ronneby-marker-light-blue;
            }

            &.light-blue-2 {
                color: $ronneby-marker-light-blue-2;
            }

            &.dark-green {
                color: $ronneby-marker-dark-green;
            }

            &.dark-green-2 {
                color: $ronneby-marker-dark-green-2;
            }

            &.light-green {
                color: $ronneby-marker-light-green;
            }

            &.red {
                color: $ronneby-marker-red;
            }

            &.yellow {
                color: $ronneby-marker-yellow;
            }

            &.grey {
                color: $ronneby-marker-grey;
            }

            &.dark-brown {
                color: $ronneby-marker-dark-brown;
            }

            &.brown {
                color: $ronneby-marker-brown;
            }

            &.orange {
                color: $ronneby-marker-orange;
            }

            &.pink {
                color: $ronneby-marker-pink;
            }

            &.dark-pink {
                color: $ronneby-marker-dark-pink;
            }

            &.purple {
                color: $ronneby-marker-purple;
            }

            &.turquise {
                color: $ronneby-marker-turquise;
            }

            &.active {
                color: $ronneby-black !important;
            }
        }

        &.live {
            z-index: 700;
        }
    }
}

.marker {
    padding: 20px;
    background-color: $ronneby-marker-grey;
    color: $ronneby-marker-grey;
    width: $marker-size;
    height: $marker-size;
    border-radius: 50% 50% 50% 0;
    position: absolute;
    transform: rotate(-45deg);
    top: -21px;
    margin: -20px 0 0 -20px;
    cursor: pointer;
    outline: none;

    &:focus-visible {
        outline: auto;
        outline-color: $ronneby-black;
    }

    &.vehicle {
        top: 0px;
        border-radius: 50%;
        z-index: 700px;
        padding: 20px;
    }

    &.dark-blue {
        background-color: $ronneby-marker-dark-blue;
        color: $ronneby-marker-dark-blue;
    }

    &.light-blue {
        background-color: $ronneby-marker-light-blue;
        color: $ronneby-marker-light-blue;
    }

    &.light-blue-2 {
        background-color: $ronneby-marker-light-blue-2;
        color: $ronneby-marker-light-blue-2;
    }

    &.dark-green {
        background-color: $ronneby-marker-dark-green;
        color: $ronneby-marker-dark-green;
    }

    &.dark-green-2 {
        background-color: $ronneby-marker-dark-green-2;
        color: $ronneby-marker-dark-green-2;
    }

    &.light-green {
        background-color: $ronneby-marker-light-green;
        color: $ronneby-marker-light-green;
    }

    &.red {
        background-color: $ronneby-marker-red;
        color: $ronneby-marker-red;
    }

    &.yellow {
        background-color: $ronneby-marker-yellow;
        color: $ronneby-marker-yellow;
    }

    &.grey {
        background-color: $ronneby-marker-grey;
        color: $ronneby-marker-grey;
    }

    &.dark-brown {
        background-color: $ronneby-marker-dark-brown;
        color: $ronneby-marker-dark-brown;
    }

    &.brown {
        background-color: $ronneby-marker-brown;
        color: $ronneby-marker-brown;
    }

    &.orange {
        background-color: $ronneby-marker-orange;
        color: $ronneby-marker-orange;
    }

    &.pink {
        background-color: $ronneby-marker-pink;
        color: $ronneby-marker-pink;
    }

    &.dark-pink {
        background-color: $ronneby-marker-dark-pink;
        color: $ronneby-marker-dark-pink;
    }

    &.purple {
        background-color: $ronneby-marker-purple;
        color: $ronneby-marker-purple;
    }

    &.turquise {
        background-color: $ronneby-marker-turquise;
        color: $ronneby-marker-turquise;
    }

    &.active {
        background-color: $ronneby-dark-grey;
        color: $ronneby-dark-grey;
        z-index: 900;
    }

    .multiple-pins {
        position: absolute;
        top: -9px;
        left: 12px;
        z-index: 700;
        height: 17px;
        width: 17px;
        background-color: $ronneby-blue-1;
        border-radius: 50%;
        transform: rotate(45deg);
        text-align: center;
        padding: 2px;

        &.dark-blue {
            background-color: $ronneby-marker-dark-blue;
            color: $ronneby-marker-dark-blue;
        }

        &.light-blue {
            background-color: $ronneby-marker-light-blue;
            color: $ronneby-marker-light-blue;
        }

        &.light-blue-2 {
            background-color: $ronneby-marker-light-blue-2;
            color: $ronneby-marker-light-blue-2;
        }

        &.dark-green {
            background-color: $ronneby-marker-dark-green;
            color: $ronneby-marker-dark-green;
        }

        &.dark-green-2 {
            background-color: $ronneby-marker-dark-green-2;
            color: $ronneby-marker-dark-green-2;
        }

        &.light-green {
            background-color: $ronneby-marker-light-green;
            color: $ronneby-marker-light-green;
        }

        &.red {
            background-color: $ronneby-marker-red;
            color: $ronneby-marker-red;
        }

        &.yellow {
            background-color: $ronneby-marker-yellow;
            color: $ronneby-marker-yellow;
        }

        &.grey {
            background-color: $ronneby-marker-grey;
            color: $ronneby-marker-grey;
        }

        &.dark-brown {
            background-color: $ronneby-marker-dark-brown;
            color: $ronneby-marker-dark-brown;
        }

        &.brown {
            background-color: $ronneby-marker-brown;
            color: $ronneby-marker-brown;
        }

        &.orange {
            background-color: $ronneby-marker-orange;
            color: $ronneby-marker-orange;
        }

        &.pink {
            background-color: $ronneby-marker-pink;
            color: $ronneby-marker-pink;
        }

        &.dark-pink {
            background-color: $ronneby-marker-dark-pink;
            color: $ronneby-marker-dark-pink;
        }

        &.purple {
            background-color: $ronneby-marker-purple;
            color: $ronneby-marker-purple;
        }

        &.turquise {
            background-color: $ronneby-marker-turquise;
            color: $ronneby-marker-turquise;
        }

        &.active {
            background-color: $ronneby-dark-grey;
        }

        .text {
            margin: 0px auto;
            font-weight: bold;
            color: $ronneby-white;
        }
    }

    .icon-container {
        background-color: $ronneby-white;
        border-radius: 100%;
        width: $marker-circle-Size;
        height: $marker-circle-Size;
        transform: rotate(45deg);
        left: ($marker-circle-Size / -2);
        position: relative;
        top: ($marker-circle-Size / -2);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;

        &.live-pin {
            z-index: 700;
        }

        .img-vehicle {
            width: 18px;
            outline: none;

            &.left {
                -webkit-transform: scaleX(-1);
                transform: scaleX(-1);
            }
        }
    }
}

.info-box-container {
    width: 92%;
    min-height: 150px;
    background-color: $ronneby-white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: fixed;
    left: 4%;
    bottom: 10px;
    z-index: 1000;
    font-size: 14px;
    font-family: $font;
    box-shadow: 0px 3px 6px $ronneby-shadow-color;
    cursor: pointer;

    &.mobile {
        overflow-x: hidden;
        overflow-y: hidden;
    }

    .info-box {
        overflow: hidden;
        width: 100%;
        height: 100%;
        border-radius: 10px;

        >div {
            &:first-child {
                padding-top: 20px;
            }

            &:last-child {
                padding-bottom: 15px;
            }
        }

        .live {
            position: relative;
            padding-left: 15px;
            padding-right: 15px;
            top: 0px;
            left: 0px;
            z-index: 0;
            margin: 0 auto 10px 0;
        }

        #scroll-container {
            max-height: 40vh;
            overflow-y: auto;
            padding-left: 15px;
            padding-right: 15px;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;

            .scroll-box {
                width: 100%;
            }

        }

        .image-container {
            width: 100%;
            display: flex;
            justify-content: center;
            align-self: flex-start;
            justify-self: center;

            .image {
                width: 100%;
                height: 100px;
                object-fit: cover;
                border-radius: 15px;
                align-self: flex-start;
            }
        }

        .title {
            width: 100%;
            margin-top: 10px;
            font-size: 18px;
            font-weight: bold;
            color: $ronneby-blue-1;
            overflow-x: hidden;
            -webkit-hyphens: auto;
            -moz-hyphens: auto;
            -ms-hyphens: auto;
            hyphens: auto;
            word-wrap: break-word;
            overflow-wrap: break-word;
        }

        .description {
            word-wrap: break-word;
            overflow-wrap: break-word;
            width: 100%;
            margin-top: 10px;
            margin-bottom: 10px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
        }

        .address {
            width: 100%;
            margin-top: 5px;
            color: $ronneby-blue-primary;
        }

        .subTypes {
            font-size: 12px;
            color: $ronneby-blue-primary;
            display: inline-flex;
            margin: 5px 5px 5px 0px;
        }

        .source {
            color: $ronneby-dark-grey;
            margin-top: 5px;
            padding-left: 15px;
            padding-right: 15px;
            padding-bottom: 10px;
            width: 100%;
            font-size: 12px;
        }

        .icon-small {
            font-size: 4px;
            color: $ronneby-blue-primary;
            vertical-align: middle;
            margin: 0px 5px 0px 0px;

            &.hide {
                display: none;
            }
        }

        .details-url {
            width: 100%;
            margin-top: 5px;
            display: flex;
            padding: 10px 20px 0;
            align-items: baseline;

            a {
                color: $ronneby-blue-1
            }

            .icon {
                margin-left: 6px;
                color: $ronneby-blue-1;
                font-size: 12px;
                position: relative;
                bottom: -1px;
            }
        }

        .booking-link {
            background-color: $ronneby-green;
            border-radius: 25px;
            width: 90%;
            margin: 15px auto 10px;
            display: flex;
            color: $ronneby-white;
            text-decoration: none;
            text-transform: none;
            outline: none;

            &:hover {
                background-color: $ronneby-light-green;
            }
        }

        .share-url {
            padding: 5px 20px 0;
            justify-content: flex-end;
            display: flex;
        }

        .multiple-events {
            width: 100%;

            .info-text {
                text-align: center;
                margin: auto 0px;
            }

            .button {
                background-color: transparent;
                color: $ronneby-blue-1;
                border-radius: 25px;
                outline: none;

                &:disabled {
                    color: $ronneby-blue-5;
                }

                &.left {
                    float: left;
                }

                &.right {
                    float: right;
                }
            }
        }

        .icon-container {
            margin-top: auto;
            padding-left: 15px;
            padding-right: 15px;
            padding-top: 10px;
            width: 100%;

            a {
                color: inherit;

                &:hover {
                    text-decoration: none;
                }
            }

            .icon-col {
                display: flex;
                color: $ronneby-blue-1;
                font-size: 18px;
                align-items: center;
                margin-bottom: 5px;
                justify-content: flex-start;

                .icon {
                    font-size: 18px;
                }
            }

            .icon-label {
                text-align: left;
                font-weight: bold;
                margin-left: 7px;
                font-size: 14px;
                color: $ronneby-blue-1;
                word-wrap: break-word;
                overflow-wrap: break-word;
            }
        }
    }    
}

.live-pin {
    position: relative;
    top: -40px;
    left: 9px;
    font-weight: bold;
    z-index: 700;
}

@media (min-width: 600px) {
    .info-box-container {
        width: 300px;
        position: relative;
        left: -150px;
        top: 10px;
        z-index: 800;

        &.right {
            &.pin-type {
                left: 40px;
                top: -60px;
            }

            &.dot-type {
                left: 30px;
                top: -40px;
            }

            &.vehicle-type {
                left: 40px;
                top: -35px;
            }

            left: 40px;
            top: -60px;

            &:after {
                right: 100%;
                top: 25px;
                border: solid transparent;
                content: "";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-color: rgba(255, 255, 255, 0);
                border-right-color: $ronneby-white;
                border-width: 20px;
                border-top-width: 10px;
                border-bottom-width: 10px;
                border-right-width: 16px;
                border-left-width: 16px;
            }
        }

        &.bottom {
            &.vehicle-type {
                top: 45px;
            }

            left: -150px;
            top: 30px;

            &:after {
                right: 140px;
                top: -32px;
                border: solid transparent;
                content: "";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-color: rgba(255, 255, 255, 0);
                border-bottom-color: $ronneby-white;
                border-width: 20px;
                border-top-width: 16px;
                border-bottom-width: 16px;
                border-right-width: 10px;
                border-left-width: 10px;
            }
        }

        .image-container {
            .image {
                height: auto;
                max-height: 140px;
                max-width: 270px;
            }
        }
    }
}