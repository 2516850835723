@import "../../../../style/Global.scss";

.filter-tool-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 10px;

    .filter-tool-title {
        font-size: 30px;
        color: $ronneby-blue-1;
        font-weight: bold;
    }
    
    .filter-tool-text {
        font-size: 16px;
        color: $ronneby-blue-1;
        margin-bottom: 40px;
    }

    .filter-tool-list {
        display: flex;
        flex-direction: column;
        max-height: 100%;
        width: 100%;
        flex-wrap: wrap;

        .filter-tool-list-container {
            margin: 0px 70px 20px 0px;

            .filter-tool-list-item {
                display: flex;

                .filter-tool-list-text {
                    margin-left: 10px;
                    color: $ronneby-blue-1;
                }

                .filter-tool-list-icon {
                    width: 21px;
                    height: 21px;
                    background-color: $ronneby-white;
                    border-radius: 50%; 
                    border: 3px solid $ronneby-white;

                    &.selected {
                        background-color: $ronneby-blue-1;
                    }
                }

                &:hover {
                    cursor: pointer;
                }
            }

        }
    }

    .filter-tool-button-container {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        margin-top: 20px;

        .filter-tool-button {
            background-color: $ronneby-blue-1;
            color: $ronneby-white;
            padding: 8px 60px;
            border-radius: 20px;
            text-transform: none;
            outline: none;

            &:disabled{
                background-color: $ronneby-disabled;
                color: $bar-gray;
            }

            &:hover {
                cursor: pointer;
            }
        }
    }

    .loading-filter-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        .loader-large {
            margin-top: 0px;
        }
    }

    .error-tool-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    
        .error-tool-icon {
            font-size: 50px;
            margin: 90px 0px 10px;
            color: $ronneby-red;
        }
    
        .error-tools-title {
            font-size: 18px;
            color: $ronneby-blue-1;
            margin: 10px 0px 90px;
        }

    }
}

@media (min-width: 960px) {
    .filter-tool-container {
        .filter-tool-list {
            max-height: 650px;
        }
    }
}