@import "../../../../style/Global.scss";

.admin-tool-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .admin-tool-icon {
        font-size: 80px;
        margin: 40px;
        color: $ronneby-blue-1;
    }

    .admin-title {
        font-size: 30px;
        color: $ronneby-blue-1;
        font-weight: bold;
        margin: 10px 0px 20px;
    }

    .admin-text {
        font-size: 16px;
        color: $ronneby-blue-1;
        margin-bottom: 10px;
    }
}