body {
    font-family: $font;
}

.logo-container {
    border-radius: 12px;
    text-align: center;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 300;
    font-weight: bold;
    padding: 7px;
    font-size: 18px;

    img {
        width: 70px;
    }
}

.nav {
    background-color: $ronneby-white !important;
    width: 100%;
    align-items: right;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $ronneby-blue-5 !important;
    font-size: 18px;
    border-bottom: 1px solid $bar-gray;

    .nav-item {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 22px;
        width: 33%;

        &.nav-item-sm {
            font-size: 18px;
            font-weight: 700;
        }

        &:first-child {
            padding-left: 35px;
            justify-content: flex-start;
        }

        &:last-child {
            padding-right: 35px;
            justify-content: flex-end;

        }

        #nav-logo {
            margin-right: 5px;

            img {
                width: 90px;
            }
        }
    }

    .drawer-toggle-div {
        position: absolute;
        top: 16px;
        left: 16px;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $ronneby-white;
        border-radius: 50%;
        box-shadow: 0px 3px 6px $ronneby-shadow-color;
        z-index: 300;

        .drawer-toggle-icon {
            width: 20px;
            height: 20px;
            color: $ronneby-blue-primary;
        }
    }
}

.drawer-menu-close-cross {
    position: absolute;
    width: 24px;
    height: 24px;
    right: 8px;
    top: 10px;
    color: $ronneby-blue-1;
}

.nav-logo-container {
    display: flex;
    align-items: center;
    margin-top: 1opx;
    font-size: 22px;
    font-weight: 700;

    #nav-logo {
        img {
            max-height: 70px;
            margin: 10px 20px 10px 20px;
        }
    }
}

.rightdrawer {
    .MuiBackdrop-root {
        opacity: 0.1 !important;
    }

    .MuiDrawer-paper {
        height: 330px;
        overflow: hidden;
    }
}

.drawer-menu {
    line-height: 1.8;

    .drawer-menu-header {
        font-weight: bold;
        padding-left: 20px;
        padding-top: 10px;
    }

    .drawer-list {
        &:first-child {
            background-color: $ronneby-menu-dark-background;
            color: $ronneby-white;
        }

        .drawer-list-item {
            display: flex;
            justify-content: left;
            align-items: center;
            outline: none;
            text-decoration: none;
            padding-top: 4px;
            padding-bottom: 4px;

            &.has-border {
                border-top: 2px solid $ronneby-menu-border;
                padding-top: 5px;
                padding-bottom: 5px;
            }

            &.active {
                font-weight: bold;
            }

            a {
                &.drawer-link {
                    margin-right: 30px;
                    outline: none;
                    text-decoration: none;
                    font-weight: 500;
                    color: lighten($primary-text-color, 25%) !important;

                    &.dark {
                        color: $ronneby-menu-dark-text-color !important;

                        &:hover {
                            color: $ronneby-menu-dark-text-color !important;
                            font-weight: bold;
                        }
                    }

                    &.light {
                        &:hover {
                            color: $primary-text-color !important;
                            font-weight: bold;
                        }
                    }

                    .drawer-item-icon {
                        margin-right: 10px
                    }
                }

                &.drawer-link-disable {
                    margin-right: 30px;
                    outline: none;
                    text-decoration: none;
                    font-weight: 500;
                    cursor: default !important;
                    color: lighten($primary-text-color, 50%) !important;

                    &:hover {
                        color: lighten($primary-text-color, 50%) !important;
                    }

                    .drawer-item-icon {
                        margin-right: 10px
                    }
                }
            }
        }
    }
}

.logout-button-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    .logout-button {
        background-color: transparent;
        color: #035e8e;
        padding: 8px 20px;
        border: 1px solid #035e8e;
        margin: 16px 20px;
        border-radius: 20px;
        text-transform: none;
        outline: none;

        &.large {
            margin: 0px 20px;
        }
    
        &:hover {
            cursor: pointer;
            color: $ronneby-white;
            background-color: $ronneby-blue-1;
        }
    }
}

@media (min-width: 960px) {
    .nav {
        height: $nav-height;

        .drawer-toggle-div {
            position: unset;
            z-index: 300;
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: none;

            .drawer-toggle-icon {
                width: 30px;
                height: 30px;
                color: $ronneby-blue-1;
                cursor: pointer;
            }
        }
    }
}