$ronneby-blue-primary: #037dba;
$ronneby-blue-1: #035e8e;
$ronneby-blue-2: #0091d4;
$ronneby-blue-3: #65bce8;
$ronneby-blue-4: #92d0f0;
$ronneby-blue-5: #acc6d6;
$ronneby-blue-6: #d5e2e7;

$ronneby-black: #000000;
$ronneby-dark-grey: #404040;
$ronneby-white: #ffffff;

$ronneby-brownish: #cdb37a;
$ronneby-yellow: #fff382;
$ronneby-red: #de0b2c;

$ronneby-green: #7EBC54;
$ronneby-light-green: #adce6d;

$ronneby-background-color: #f8f8f8;
$ronneby-shadow-color: #4D4D4D29;
$bar-gray: #a1a1a1;

$button-active-color: $ronneby-blue-1;
$primary-text-color: $ronneby-black;

// LIVE PULS
$ronneby-live-light: lighten($ronneby-red, 5%);
$ronneby-live-dark: darken($ronneby-red, 15%);
$ronneby-live-pin-light: lighten($ronneby-blue-1, 20%);
$ronneby-live-pin-dark: $ronneby-blue-1;

// MARKER
$ronneby-marker-dark-blue: $ronneby-blue-1;
$ronneby-marker-light-blue: $ronneby-blue-primary;
$ronneby-marker-light-blue-2: #00AAFF;
$ronneby-marker-dark-green: #00963D;
$ronneby-marker-dark-green-2: #017033;
$ronneby-marker-light-green: #7EBC54;
$ronneby-marker-red: #D3454E;

$ronneby-marker-grey: #808080;
$ronneby-marker-yellow: #EDD700;
$ronneby-marker-dark-brown: #6d5827;
$ronneby-marker-brown: #B59243;
$ronneby-marker-orange: #DB8E12;
$ronneby-marker-pink: #D86DD0;
$ronneby-marker-dark-pink: #96005A;
$ronneby-marker-purple: #935FC7;
$ronneby-marker-turquise: #03BAA5;

//MENU
$ronneby-menu-dark-text-color: $primary-text-color;
$ronneby-menu-dark-background:  $ronneby-blue-5;
$ronneby-menu-border:  lighten( $ronneby-marker-grey,25%);

$ronneby-disabled: #d9d4d4;




//FONT
$font: 'Work Sans', arial, helvetica, sans-serif !important;
