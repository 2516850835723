.olofstrom {
    @import "../../../src/style/ThemeOlofstrom.scss";
    @import "./AdminToolbox.scss";

    .admin-container {
        .admin-options-container {
            .tool-option {
                &:hover {
                    background-color: #acc6d6 !important;
                }
            }
        }
    }
}

.ronneby {
    @import "../../../src/style/ThemeRonneby.scss";
    @import "./AdminToolbox.scss";
}